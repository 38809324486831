import { useRouter } from 'next/router';
import React, { useContext, useState } from 'react';

import classNames from 'classnames';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';

import { envConfig } from '../../../env.config';
import { getSessionId } from '../../utils/cookies';
import DatahubService from '../../utils/datahub';
import { EVENT_ACTIONS, SEARCH_SOURCES } from '../../utils/datahub.constants';
import { loaderStaticImage } from '../../utils/image';
import { ProfileContext } from '../core/Provider/ProfileProvider';

import useFeatureSwitch from '../../custom-hooks/useFeatureSwitch';
import useUrlAuth from '../../custom-hooks/useUrlAuth';

import useAuthModule from 'components/Auth';
import {
  getTopificationRouter,
  mountMarketplacePage,
  mountMarketplaceResultsPage
} from 'utils/url';
import { subsPerTopic } from '../../constants';
import { Placeholder } from './Placeholder/';

const Header = (props) => {
  const [loadHeader, setLoadHeader] = useState(false);

  const context = useContext(ProfileContext);
  const {
    lng,
    showSearch,
    customLogo,
    homeURL,
    onLogout,
    searchTerm,
    isMobile,
    ip,
    showCategoryMenu,
    searchSuggestions,
    project,
    environment,
    loginOnClick,
    signUpOnClick,
    logoutOnClick,
    onSuggestionClick,
    onAutoCompleteClick,
    registerLabel,
    headerRef,
    t,
    accessButtonsDirection,
    showHeaderSignInSignUp,
    isMarketplace,
    profile,
    store
  } = props;

  const {
    query: { sck, src, preview, draft } = {},
    actions,
    isModalOpen
  } = context || {};
  const { CAS_IS_ENABLED } = useFeatureSwitch();
  const {
    loginUrl = () => {},
    logoutUrl = () => {},
    signUpUrl = () => {}
  } = useUrlAuth({ CAS_IS_ENABLED });
  const { user } = useAuthModule({ store, context, CAS_IS_ENABLED });
  const locale = context?.query?.lng || lng;
  const router = useRouter();

  const mountHeader = (data) => {
    const MainHeader = dynamic(() =>
      import('@hotmart/app-display-components/dist/MainHeader')
    );
    return <MainHeader {...data} />;
  };

  const categories =
    subsPerTopic &&
    Object.entries(subsPerTopic).map(([category, subcategoryList]) => {
      const categoryLabel = t(`topification.${category}`);
      const categoryUrl = getTopificationRouter({
        language: locale,
        topification: category
      });

      const subcategoriesUrl = subcategoryList.map((subcategory) => {
        const subcategoryUrl = getTopificationRouter({
          language: locale,
          topification: category,
          topic: subcategory
        });
        const subcategoryLabel = t(`topic.${subcategory}`);

        return {
          categoryUrl: subcategoryUrl,
          label: subcategoryLabel,
          slug: subcategory
        };
      });

      return {
        categoryUrl,
        label: categoryLabel,
        slug: category,
        subcategories: subcategoriesUrl ?? []
      };
    });

  const data = {
    user,
    language: locale,
    showSearch,
    homeURL: mountMarketplacePage(locale) || homeURL,
    fallbackImage: loaderStaticImage('static/images/placeholder/avatar.png'),
    showNavigation: true,
    onLogout,
    customLogo,
    searchTerm,
    searchPlaceholder: t('search.what_would_you_like_to_learn'),
    showCategoryMenu,
    searchSuggestions,
    showSuggestions: false,
    urlParams: {},
    extraSearchParameters: {
      showCategories: true,
      showShadow: false,
      showProducts: true,
      envConfig: {
        DISPLAY_SEARCH_ENDPOINT: envConfig.CONTENT_ENDPOINT,
        RESULTS_PAGE: mountMarketplaceResultsPage(locale),
        HUB_ENDPOINT: envConfig?.HUB_ENDPOINT
      },
      callback: async (value) => {
        DatahubService.sendEvent({
          action: EVENT_ACTIONS.SEARCH,
          locale: locale,
          term: value,
          ip,
          sessionId: getSessionId(),
          source: SEARCH_SOURCES.HEADER,
          path: window.location.href
        });
      }
    },
    project,
    environment,
    setIsModalOpen: async () => actions.setIsModalOpen(!isModalOpen),
    loginUrl: loginUrl(),
    signUpUrl: envConfig?.APP,
    logoutUrl: logoutUrl(),
    loginOnClick,
    signUpOnClick,
    logoutOnClick,
    onSuggestionClick,
    onAutoCompleteClick,
    headerSignUpLabel: registerLabel,
    menuSignUpLabel: registerLabel,
    showHeaderSignInSignUp,
    accessButtonsDirection,
    isMarketplace,
    categories,
    token: store?.tokenProfile
  };

  data.logoutOnClick = () => localStorage.removeItem('cas-js:user');
  if (sck) {
    data.urlParams.sck = sck;
  }

  if (src) {
    data.urlParams.src = src;
  }

  return (
    <div
      className={classNames(
        { 'header-preview': preview || draft },
        { 'header-product': !isMarketplace },
        { 'header-marketplace': isMarketplace }
      )}
      ref={headerRef}
    >
      {loadHeader ? (
        mountHeader(data)
      ) : (
        <Placeholder
          setLoadHeader={setLoadHeader}
          isMarketplace={isMarketplace}
          isMobile={isMobile}
          t={t}
          customLogo={customLogo}
          language={lng}
        />
      )}
    </div>
  );
};

Header.propTypes = {
  lng: PropTypes.string,
  showSearch: PropTypes.bool,
  customLogo: PropTypes.object,
  homeURL: PropTypes.string,
  onLogout: PropTypes.func,
  searchTerm: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  showCategoryMenu: PropTypes.bool,
  searchSuggestions: PropTypes.array,
  project: PropTypes.string,
  environment: PropTypes.string,
  loginOnClick: PropTypes.func,
  signUpOnClick: PropTypes.func,
  logoutOnClick: PropTypes.func,
  onSuggestionClick: PropTypes.func,
  onAutoCompleteClick: PropTypes.func,
  registerLabel: PropTypes.string,
  isMobile: PropTypes.bool,
  t: PropTypes.func,
  accessButtonsDirection: PropTypes.string,
  showHeaderSignInSignUp: PropTypes.bool,
  isMarketplace: PropTypes.bool,
  store: PropTypes.object
};

Header.defaultProps = {
  showSearch: false,
  onLogout: () => {}
};

export default React.memo(Header);
